const loadingButton = {
  MuiLoadingButton: {
    styleOverrides: {
      loadingIndicator: {
        position: 'static' as const,
      },
    },
  },
};

export default loadingButton;
