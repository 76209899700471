const accordionSummary = {
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
    },
  },
};

export default accordionSummary;
