const popover = {
  MuiPopover: {
    styleOverrides: {
      paper: {
        width: '350px',
        borderRadius: '8px',
        filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.08))',
      },
    },
  },
};

export default popover;
