import { createTheme } from '@mui/material/styles';
import palette from './color-palette';
import typography, { MuiTypography, MuiCssBaseline } from './typography';
import MuiOverrides from './MuiOverrides';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    ms: true;
    dl: true;
  }
}

const MuiUseMediaQuery = {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
};

export const theme = createTheme({
  spacing: 4,
  components: { ...MuiCssBaseline, ...MuiOverrides.components, ...MuiTypography, ...MuiUseMediaQuery },
  palette: { ...palette },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      ms: 548,
      md: 834,
      dl: 1096,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: typography,
});
