import { styled, Box } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const Wrapper = styled(Box)`
  width: 100%;
`;

export const SpinnerContainer = styled(Box)`
  height: calc(100vh - ${theme.spacing(20)});
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
