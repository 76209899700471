const snackbar = {
  MuiSnackbar: {
    styleOverrides: {
      root: {
        '@media (min-width: 360px)': {
          position: 'fixed',
          top: 0,
          maxWidth: 'calc(100% - 64px)',
          width: 'calc(100% - 160px)',
        },
        '@media (min-width: 1095px)': {
          left: 'calc(50% + 147px)',
          width: 'calc(100% - 494px)',
          maxWidth: '593px',
        },
      },
    },
  },
};

export default snackbar;
