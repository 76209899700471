const alertitle = {
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
  },
};

export default alertitle;
